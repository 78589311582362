import ConfirmButton from '@components/common/ConfirmButton';
import React, { useCallback, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ReactComponent as ChevronIcon } from '@assets/icons/chevron_left_icon.svg';
import DateSelector from '@components/common/selector/dateSelector/DateSelector';
import hexToRGBA from '@utils/hexToRGBA';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import LocationSelector from '@components/common/selector/locationSelector/LocationSelector';
import TenseSelector from '@components/common/selector/TenseSelector';
import CarbonCategorySelector from '@components/common/selector/carbonCategorySelector/CarbonCategorySelector';
import useMapStore, { CarbonBudgetDataType } from 'stores/mapStore';
import { TCarbonSourceDataType } from 'interfaces/mapInterface';
import { convertArrayToLocation, convertLocationToArray } from '@utils/convertLocationToArray';
import dayjs from 'dayjs';
import { TWidthType } from '@hooks/useWidthType';

export type TConditionInput = {
  startDate: string;
  endDate: string;
  location: string[];
  tense: string;
  carbonCategory: TCarbonSourceDataType[];
};

type TProps = {
  widthType: TWidthType;
};

const ConditionPanel = ({ widthType }: TProps) => {
  const { selectDataCondition, setDataCondition } = useMapStore();
  const [isPanelOpen, setIsPanelOpen] = useState(widthType !== 'mobile' ? true : false);
  const defaultValues: TConditionInput = {
    startDate: selectDataCondition.startDate.format('YYYY-MM'),
    endDate: selectDataCondition.endDate.format('YYYY-MM'),
    location: convertLocationToArray(selectDataCondition.location),
    tense: selectDataCondition.tense,
    carbonCategory: selectDataCondition.dataType,
  };

  const methods = useForm<TConditionInput>({ defaultValues, mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isValid },
  } = methods;

  const handleFormSubmit: SubmitHandler<TConditionInput> = ({ startDate, endDate, location, tense, carbonCategory }) => {
    const dataCondition: CarbonBudgetDataType = {
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      location: convertArrayToLocation(location),
      tense,
      dataType: carbonCategory,
    };

    setDataCondition(dataCondition);
  };

  const handleButtonClick = useCallback((e: React.MouseEvent) => {
    e.preventDefault();
    setIsPanelOpen((prev) => !prev);
  }, []);

  useEffect(() => {
    console.log(widthType);
  }, []);

  return (
    <Container $isPanelOpen={isPanelOpen}>
      <FormProvider {...methods}>
        <PanelForm onSubmit={handleSubmit(handleFormSubmit)}>
          <SelectorContainer>
            <DateSelector selectorId="map-date-selector" startDateInputName="startDate" endDateInputName="endDate" />
            <LocationSelector selectorId="map-location-selector" inputName="location" />
            <TenseSelector selectorId="map-tense-selector" inputName="tense" />
            <CarbonCategorySelector selectorId="map-carbon-category-selector" inputName="carbonCategory" />
          </SelectorContainer>
          <ButtonContainer>
            <ConfirmButton text="검색하기" width="150px" disabled={!isValid} />
            <PanelCloseButton $isPanelOpen={isPanelOpen} onClick={handleButtonClick}>
              <ChevronIcon />
            </PanelCloseButton>
          </ButtonContainer>
        </PanelForm>
      </FormProvider>
      <PanelOpenButton onClick={handleButtonClick}>
        <ChevronIcon />
      </PanelOpenButton>
    </Container>
  );
};

export default ConditionPanel;

const Container = styled.div<{ $isPanelOpen: boolean }>`
  display: flex;
  width: calc(100% + 20px);
  z-index: 3;
  position: absolute;
  top: 0px;
  left: 0px;
  transform: translateX(${({ $isPanelOpen }) => ($isPanelOpen ? 0 : 'calc(-100% + 20px)')});

  ${({ theme, $isPanelOpen }) =>
    theme.media.tablet(`
      width: 100%;
      flex-direction: column;
      transform: translateY(${$isPanelOpen ? 0 : 'calc(-100% + 55px)'});
  `)}

  ${({ theme, $isPanelOpen }) =>
    theme.media.mobile(`
      width: 100%;
      flex-direction: column;
      transform: translateY(${$isPanelOpen ? 0 : 'calc(-100% + 55px)'});
  `)}
`;

const PanelForm = styled.form`
  display: flex;
  justify-content: space-between;
  width: 100%;
  background: ${({ theme }) => hexToRGBA(theme.newColors.dark1, 0.5)};
  box-shadow: 2px 5px 10px 0px rgba(0, 0, 0, 0.3);
  padding: 10px 5px 10px 20px;
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);

  ${({ theme }) =>
    theme.media.tablet(`
      flex-direction: column;
      padding: 20px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      flex-direction: column;
      padding: 20px;
  `)}
`;

const SelectorContainer = styled.div`
  display: flex;
  gap: 10px;
  width: 100%;
  margin-right: 10px;

  & > div:nth-child(1) {
    flex: 1 1 250px;
    min-width: 200px;
    max-width: 250px;
  }

  & > div:nth-child(2) {
    flex: 1 1 605px;
    min-width: 450px;
    max-width: 605px;
  }

  & > div:nth-child(3),
  & > div:nth-child(4) {
    flex: 1 1 274px;
    min-width: 160px;
    max-width: 274px;
  }

  ${({ theme }) =>
    theme.media.tablet(`
      flex-wrap: wrap;

      & > div:nth-child(1),
      & > div:nth-child(2),
      & > div:nth-child(3),
      & > div:nth-child(4) {
        min-width: initial;
        max-width: initial;
        flex: 1 1 48%;
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      flex-wrap: wrap;

      & > div:nth-child(1),
      & > div:nth-child(2),
      & > div:nth-child(3),
      & > div:nth-child(4) {
        min-width: initial;
        max-width: initial;
        flex: 1 1 100%;
      }
  `)}
`;

const ButtonContainer = styled.div`
  display: flex;
  gap: 5px;

  ${({ theme }) =>
    theme.media.tablet(`
      align-items: center;
      flex-direction: column;
      padding-top: 30px;
      gap: 20px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      align-items: center;
      flex-direction: column;
      padding-top: 30px;
      gap: 20px;
  `)}
`;

const PanelCloseButton = styled.button<{ $isPanelOpen: boolean }>`
  padding: 0 15px;

  ${({ theme, $isPanelOpen }) =>
    theme.media.tablet(`
      svg {
        transform: ${`rotate(${$isPanelOpen ? '90' : '270'}deg)`}
      }
  `)}
  ${({ theme, $isPanelOpen }) =>
    theme.media.mobile(`
      svg {
        transform: ${`rotate(${$isPanelOpen ? '90' : '270'}deg)`}
      }
  `)};
`;

const PanelOpenButton = styled.button`
  width: 20px;
  height: 60px;
  background-color: ${({ theme }) => theme.colors.gray};
  border-radius: 0 5px 5px 0;

  svg {
    transform: rotate(180deg);
  }

  ${({ theme }) =>
    theme.media.tablet(`
      display: none;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      display: none;
  `)}
`;
