import { aboutData } from '@static/aboutData';
import hexToRGBA from '@utils/hexToRGBA';
import React, { Fragment } from 'react';
import styled from 'styled-components';
import { ReactComponent as ArrowIcon } from '@assets/icons/chevron_right_icon.svg';

const Roadmap = () => {
  const data = aboutData.roadmap.roadmap;
  return (
    <Container>
      <MapContainer>
        {data.map.map((item) => (
          <Map key={item.title}>
            <div className="title">{item.title}</div>
            <div className="image">
              <img src={item.src} alt={`${item.title} 지도`} />
            </div>
          </Map>
        ))}
      </MapContainer>

      <ContentContainer>
        <Timeline>
          <Line />
          <TimelineEventContainer>
            {data.history.map((item) => (
              <TimelineEvent key={item.year}>
                <div className="circle"></div>
                <div className="text">
                  <p className="year">{item.year}</p>
                  <p>{item.text1}</p>
                  <p className="year-desc">
                    <span>{item.text2[0]}</span>
                    {item.text2[1] && <span>{item.text2[1]}</span>}
                  </p>
                </div>
              </TimelineEvent>
            ))}
          </TimelineEventContainer>
        </Timeline>

        <ContentFlex>
          {data.plan.map((item) => (
            <Content key={item.title}>
              <div className="title">{item.title}</div>
              <ContentItems className="items">
                {item.data.map((subitem, idx) => (
                  <Fragment key={subitem}>
                    <div className="item">{subitem}</div>
                    {item.data.length !== idx + 1 && (
                      <div className="arrow">
                        <ArrowIcon />
                      </div>
                    )}
                  </Fragment>
                ))}
              </ContentItems>
            </Content>
          ))}
        </ContentFlex>
      </ContentContainer>
    </Container>
  );
};

export default Roadmap;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
`;

const MapContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 22.5px;

  ${({ theme }) =>
    theme.media.mobile(`
      grid-template-columns: repeat(1, 1fr);
      gap: 10px;
  `)}
`;

const Map = styled.div`
  border-radius: 5px;
  background: ${({ theme }) => hexToRGBA(theme.newColors.dark2, 0.5)};

  display: flex;
  padding: 20px;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
`;

const Timeline = styled.div`
  height: 126px;

  ${({ theme }) =>
    theme.media.tablet(`
      margin-top: 0;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      margin-top: 0;
      height: 100%;

      display: flex;
  `)}
`;

const Line = styled.div`
  width: 100vw;
  position: relative;
  left: 50%;
  right: 50%;
  margin-inline: -50vw;

  background-color: ${({ theme }) => theme.newColors.primary};
  height: 3px;

  ${({ theme }) =>
    theme.media.tablet(`
      position: static;
      margin: auto;
      width: 100%;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      height: 860px;
      width: 3px;
      position: static;
      margin-inline: 0;
      order: 1;
  `)}
`;

const TimelineEventContainer = styled.div`
  display: flex;
  justify-content: center;
  gap: 16px;
  position: relative;
  left: 78px;

  ${({ theme }) =>
    theme.media.tablet(`
      position: static;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      gap: 70px;
      position: static;
      flex-direction: column;
      justify-content: flex-start;
  `)}
`;

const TimelineEvent = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  z-index: 1;
  max-width: 205px;
  width: 100%;

  margin-top: -10px;
  gap: 20px;

  div.circle {
    width: 20px;
    height: 20px;
    border-radius: 50px;

    background-color: ${({ theme }) => theme.newColors.white};
    border: 4px solid ${({ theme }) => theme.newColors.primary};
  }

  div.text {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;

    div {
      color: ${({ theme }) => theme.newColors.white};

      font-size: 18px;
      font-variation-settings: 'wght' 600;
      line-height: 22px; /* 122.222% */
    }

    p.year {
      color: ${({ theme }) => theme.newColors.primary};
    }

    p.year-desc {
      display: flex;
      gap: 4px;
    }
  }

  ${({ theme }) =>
    theme.media.tablet(`
      div.text {
        div:not(.year) {
          font-size: 16px;
          line-height: 25px; /* 156.25% */
          letter-spacing: -0.32px;
        }

        div:last-child {
          text-align: center;
          word-break: keep-all;
        }

        p.year-desc {
          flex-direction: column;
          align-items: center;
        }
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      flex-direction: row;
      align-items: flex-start;
      height: 112px;
      position: relative;
      left: 11px;
      top: 10px;

      div.circle {
        order: 1;
        flex-shrink: 0;
      }

      div.text {
        width: 90px;
        align-items: flex-end;
        text-align: end;
        word-break: keep-all;

        p.year-desc {
          display: block;

          & > span:last-child {
            margin-left: 3px;
          }
        }
      }
  `)}
`;

const ContentContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  margin-top: 50px;

  ${({ theme }) =>
    theme.media.tablet(`
      margin-top: 0;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      gap: 25px;
      height: 864px;
      flex-direction: row;
      justify-content: space-evenly;
  `)}
`;

const ContentFlex = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;

  ${({ theme }) =>
    theme.media.mobile(`
      gap: 20px;
      flex-direction: row;
      width: 100%;
  `)}
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  div.title {
    color: ${({ theme }) => theme.newColors.white};
    font-size: 25px;
    font-variation-settings: 'wght' 800;
    line-height: 40px; /* 160% */
    letter-spacing: -0.5px;

    margin-right: 30px;
  }

  &:last-child .item:last-child {
    grid-column: span 5;
  }

  ${({ theme }) =>
    theme.media.tablet(`
      flex-direction: column;
      align-items: flex-start;
      width: 100%;
      gap: 10px;

      div.title {
        font-size: 16px;
        font-variation-settings: 'wght' 600;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
        margin-right: 0;
      }

      &:last-child .item:last-child {
        flex: 1;
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      flex-direction: column;
      gap: 10px;
      flex: 1;

      div.title {
        font-size: 15px;
        font-variation-settings: 'wght' 600;
        line-height: 20px; /* 125% */
        letter-spacing: -0.32px;
        margin-right: 0;
      }

      &:last-child .item:last-child {
        flex-grow: 1;
      }
  `)}
`;

const ContentItems = styled.div`
  width: 100%;
  display: grid;
  grid-template-columns: repeat(4, 1fr 6px) 1fr;
  grid-auto-flow: column;
  align-items: stretch;
  gap: 5px;
  flex: 1;

  .arrow {
    display: flex;
    align-items: center;

    & > svg {
      flex-shrink: 0;
    }
  }

  div.item {
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 12px;
    width: 100%;
    flex: 1;
    background-color: ${({ theme }) => theme.newColors.dark3};

    color: ${({ theme }) => theme.newColors.white};
    font-size: 16px;
    font-variation-settings: 'wght' 600;
    line-height: 20px; /* 125% */
    letter-spacing: -0.32px;
  }

  ${({ theme }) =>
    theme.media.tablet(`
      gap: 10px;

      & > div.item {
        min-width: 110px;
        font-size: 14px;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.28px;
        text-align: center;
        word-break: keep-all;
        padding: 12px 20px;
        flex: initial;
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      display: flex;
      flex-direction: column;
      align-items: center;
      gap: 7px;
      height: 834px;
      flex: initial;
      flex-shrink: 0;

      & > div.item {
        height: 146px;
        font-size: 14px;
        line-height: 18px; /* 128.571% */
        letter-spacing: -0.28px;
        flex: none;
        text-align: center;
        word-break: keep-all;
      }
      
      .arrow > svg {
        transform: rotate(90deg);
      }
  `)}
`;
