import hexToRGBA from '@utils/hexToRGBA';
import React from 'react';
import styled from 'styled-components';

type Props = {
  data: string[];
};

const PurposeBox = ({ data }: Props) => {
  return (
    <Container>
      {data.map((item, idx) => (
        <div className={'text'} key={idx}>
          {item}
        </div>
      ))}
    </Container>
  );
};

export default PurposeBox;

const Container = styled.div`
  padding: 30px;
  border-radius: 8px;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.dark2, 0.5)};

  display: flex;
  flex-direction: column;
  gap: 30px;

  div.text {
    color: ${({ theme }) => theme.newColors.white};
    font-variation-settings: 'wght' 400;
    font-size: 18px;
    font-weight: 400;
    line-height: 30px; /* 166.667% */
    letter-spacing: -0.36px;
  }

  ${({ theme }) =>
    theme.media.tablet(`
      div.text {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px; /* 175% */
        letter-spacing: -0.32px;
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      div.text {
        font-size: 16px;
        font-weight: 400;
        line-height: 28px; /* 175% */
        letter-spacing: -0.32px;
      }
  `)}
`;
