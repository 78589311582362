import styled from 'styled-components';
import { ReactComponent as InfoIcon } from '@assets/icons/info_icon.svg';
import hexToRGBA from '@utils/hexToRGBA';
import DateSelector from '@components/common/selector/dateSelector/DateSelector';
import { FormProvider, SubmitHandler, useForm } from 'react-hook-form';
import { newColors } from '@styles/theme';
import TenseSelector from '@components/common/selector/TenseSelector';
import CarbonCategorySelector from '@components/common/selector/carbonCategorySelector/CarbonCategorySelector';
import ConfirmButton from '@components/common/ConfirmButton';
import LocationSelector from '@components/common/selector/locationSelector/LocationSelector';
import useDashboardStore, { TCarbonCompare } from 'stores/dashboardStore';
import dayjs from 'dayjs';
import { carbonCategoryKey } from '@static/mapData';
import { TCarbonSourceDataType, TLocationDataType } from 'interfaces/mapInterface';
import { convertArrayToLocation } from '@utils/convertLocationToArray';

type TCarbonCompareSearchInput = {
  startDate: string;
  endDate: string;
  tense: string;
  carbonCategory: TCarbonSourceDataType[];
  location1: [];
  location2: [];
  location3: [];
};

const defaultValues: TCarbonCompareSearchInput = {
  startDate: '',
  endDate: '',
  tense: '',
  carbonCategory: [],
  location1: [],
  location2: [],
  location3: [],
};

const CarbonCompareSearch = () => {
  const methods = useForm<TCarbonCompareSearchInput>({ defaultValues, mode: 'onChange' });
  const {
    handleSubmit,
    formState: { isValid },
    watch,
  } = methods;
  const { setCarbonCompare } = useDashboardStore();

  const isLocationSelected = watch('location1').length || watch('location2').length || watch('location3').length;

  const handleFormSubmit: SubmitHandler<TCarbonCompareSearchInput> = ({
    startDate,
    endDate,
    tense,
    carbonCategory,
    location1,
    location2,
    location3,
  }) => {
    const location: TLocationDataType[] = [];
    if (location1 && location1.length > 0) location.push(convertArrayToLocation(location1));
    if (location2 && location2.length > 0) location.push(convertArrayToLocation(location2));
    if (location3 && location3.length > 0) location.push(convertArrayToLocation(location3));

    const dataCondition: TCarbonCompare = {
      startDate: dayjs(startDate),
      endDate: dayjs(endDate),
      tense,
      location,
      dataType: carbonCategory,
    };

    setCarbonCompare(dataCondition);
  };

  return (
    <FormProvider {...methods}>
      <Form onSubmit={handleSubmit(handleFormSubmit)}>
        <ContentsContainer>
          <Header>
            <span className="title">데이터 조건 설정</span>
            {/* <InfoButton type="button">
              <InfoIcon />
              안내사항
            </InfoButton> */}
          </Header>
          <SelectorContainer>
            <DateSelector
              selectorId="compare-date-selector"
              startDateInputName="startDate"
              endDateInputName="endDate"
              backgroundColor={newColors.dark2}
            />
            <TenseSelector selectorId="compare-tense-selector" inputName="tense" backgroundColor={newColors.dark2} />
            <CarbonCategorySelector
              selectorId="compare-carbon-category-selector"
              inputName="carbonCategory"
              backgroundColor={newColors.dark2}
            />
          </SelectorContainer>
          <Hr />
          <LocationList>
            {Array.from({ length: 3 }).map((_, index) => (
              <li key={index}>
                <span className="group">그룹 {index + 1}</span>
                <LocationSelector
                  selectorId={`compare-location-selector-${index + 1}`}
                  inputName={`location${index + 1}`}
                  backgroundColor={newColors.dark2}
                  required={false}
                />
              </li>
            ))}
          </LocationList>
        </ContentsContainer>
        <ButtonContainer>
          <ConfirmButton text="검색하기" width="150px" disabled={!(isValid && isLocationSelected)} />
        </ButtonContainer>
      </Form>
    </FormProvider>
  );
};

export default CarbonCompareSearch;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  gap: 50px;
  border: 2px solid ${({ theme }) => theme.newColors.dark2};
  border-radius: 8px;
  padding: 20px 20px 30px 20px;
`;

const ContentsContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 20px;
`;

const Header = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;

  span.title {
    font-size: 18px;
    font-weight: 700;
    line-height: 30px;
    letter-spacing: -0.36px;
  }
`;

const InfoButton = styled.button`
  display: flex;
  gap: 5px;
  padding: 8px 10px;
  background-color: ${({ theme }) => theme.newColors.grey1};
  border-radius: 5px;
`;

const SelectorContainer = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 274px);
  gap: 10px;

  ${({ theme }) =>
    theme.media.tablet(`
      grid-template-columns: repeat(3, 274px);
      & > div:nth-child(1) {
        grid-column: 1 / 1;
        grid-row: 1 / 2;
      }

      & > div:nth-child(2) {
        grid-column: 1 / 2;
        grid-row: 2 / 3;
      }
      
      & > div:nth-child(3) {
        grid-column: 2 / 3;
        grid-row: 2 / 3;
      }
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      grid-template-columns: 1fr 1fr 1fr;
      & > div:nth-child(1) {
        grid-column: 1 / 4;
        grid-row: 1 / 2;
      }

      & > div:nth-child(2) {
        grid-column: 1 / 4;
        grid-row: 2 / 3;
      }
      
      & > div:nth-child(3) {
        grid-column: 1 / 4;
        grid-row: 3 / 4;
      }
  `)}
`;

const Hr = styled.hr`
  width: 100%;
  height: 1px;
  border: none;
  background-color: ${({ theme }) => hexToRGBA(theme.newColors.grey1, 0.3)};
`;

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  width: 100%;
`;

const LocationList = styled.ul`
  display: flex;
  flex-direction: column;
  gap: 10px;
  width: 100%;
  max-width: 845px;

  li {
    display: flex;
    align-items: center;
    gap: 10px;

    span.group {
      font-size: 16px;
      font-weight: 400;
      line-height: 20px;
      letter-spacing: -0.32px;
      white-space: nowrap;
      min-width: 40px;
    }
  }

  ${({ theme }) =>
    theme.media.mobile(`
      gap: 20px;
      li {
        flex-direction: column;
        align-items: start;
      }
  `)}
`;
