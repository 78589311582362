import React from 'react';
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Legend } from 'chart.js';
import { Bar } from 'react-chartjs-2';
import { IChartDataType } from 'interfaces/chartInterface';
import styled from 'styled-components';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Legend);

const options = {
  maintainAspectRatio: false,
  responsive: true,
  indexAxis: 'y' as const,
  elements: {
    bar: {
      borderRadius: { bottomLeft: 0, bottomRight: 3, topLeft: 0, topRight: 3 },
    },
  },
  scales: {
    x: { border: { display: false }, ticks: { padding: 10, autoSkip: true, autoSkipPadding: 50, maxRotation: 0, minRotation: 0 } },
    y: { grid: { display: false }, ticks: { crossAlign: 'far' as 'far' | 'center' | 'near' | undefined } },
  },
  plugins: {
    legend: {
      display: false,
    },
    title: {
      display: false,
    },
    zoom: {
      zoom: {
        wheel: {
          enabled: true,
        },
        drag: {
          enabled: true,
        },
        pinch: {
          enabled: true,
        },
        mode: 'x' as 'x' | 'y' | 'xy',
      },
    },
  },
};

type Props = {
  data: { labels: string[]; datasets: IChartDataType[] };
};

const BarChart = ({ data }: Props) => {
  return (
    <Container>
      <Bar options={options} data={data} />
    </Container>
  );
};

export default BarChart;

const Container = styled.div`
  position: relative;
  height: 350px;
  width: 100%;
`;
