import { INetworkData } from 'interfaces/networkInterface';

export const networkLegendData = {
  snu: { type: 'snu', title: 'KCP-서울대', color: '#A07BFF' },
  khu: { type: 'khu', title: 'KCP-경희대', color: '#FF7FA1' },
  jnu: { type: 'jnu', title: 'KCP-전남대', color: '#52EEA3' },
  knu: { type: 'knu', title: 'KCP-강원대', color: '#FFC538' },
  partner: { type: 'partner', title: 'KCP-협력체', color: '#20CBDD' },
};

export const networkData: INetworkData[] = [
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '서울대학교 220동',
    coordinates: [126.954, 37.464],
    equipments: [
      {
        name: 'EM27/SUN Serial Number 142',
        materials: ['XCO2', 'XCH4', 'XCO'],
        year: '2020',
        operate_org: '서울대학교',
        owner: '서울대학교',
      },
      {
        name: 'EM27/SUN Serial Number 144',
        materials: ['XCO2', 'XCH4', 'XCO'],
        year: '2021',
        operate_org: '서울대학교',
        owner: '서울대학교',
      },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '국수봉',
    coordinates: [126.9555, 37.46251],
    equipments: [
      { name: 'SNUCO2M (LI-850)', materials: ['CO2 Concentration'], year: '2021', operate_org: '서울대학교', owner: '산림과학원' },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '용산',
    coordinates: [126.9637, 37.52384],
    equipments: [
      { name: 'SNUCO2M (LI-850)', materials: ['CO2 Concentration'], year: '2018', operate_org: '서울대학교', owner: '서울대학교' },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '남산타워 상층',
    coordinates: [126.9883, 37.5512],
    equipments: [
      { name: 'SNUCO2M (LI-850)', materials: ['CO2 Concentration'], year: '2019', operate_org: '서울대학교', owner: '산림과학원' },
      { name: 'G2401', materials: ['CO2 Concentration'], year: '2024 (예정)', operate_org: '서울대학교', owner: '산림과학원' },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '불광',
    coordinates: [126.9340046302, 37.6109990655],
    equipments: [{ name: 'G2301', materials: ['CO2 Concentration'], year: '2022', operate_org: '환경과학원', owner: '환경과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '안산',
    coordinates: [126.8283102228, 37.3201871597],
    equipments: [{ name: 'G2301', materials: ['CO2 Concentration'], year: '2022', operate_org: '환경과학원', owner: '환경과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '양평',
    coordinates: [127.410568468, 37.4951501463],
    equipments: [
      { name: 'T360M', materials: ['CO2 Concentration'], year: '2021', operate_org: '수도권대기환경청', owner: '수도권대기환경청' },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '관악산',
    coordinates: [126.9659244073, 37.4446938065],
    equipments: [
      {
        name: 'G2301',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: '서울시 보건환경연구원',
        owner: '서울시 보건환경연구원',
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '남산타워 하층',
    coordinates: [126.9880222185, 37.5509968327],
    equipments: [
      {
        name: 'G2301-i',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: '서울시 보건환경연구원',
        owner: '서울시 보건환경연구원',
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '올림픽공원',
    coordinates: [127.1160158535, 37.5209837604],
    equipments: [
      {
        name: 'G2301-i',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: '서울시 보건환경연구원',
        owner: '서울시 보건환경연구원',
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '평택',
    coordinates: [126.9315713448, 36.9859358327],
    equipments: [
      {
        name: 'G2401',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: '경기도 보건환경연구원',
        owner: '경기도 보건환경연구원',
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '김포',
    coordinates: [126.552930978, 37.7153305585],
    equipments: [
      {
        name: 'G2401',
        materials: ['CO2 Concentration'],
        year: '2021',
        operate_org: '경기도 보건환경연구원',
        owner: '경기도 보건환경연구원',
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '내포',
    coordinates: [126.6676036998, 36.6531629999],
    equipments: [
      { name: 'G2301', materials: ['CO2 Concentration'], year: '2022', operate_org: '충남 보건환경연구원', owner: '충남 보건환경연구원' },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '홍릉',
    coordinates: [127.0475117, 37.603306],
    equipments: [
      {
        name: 'SNUCO2M (LI-850)',
        materials: ['CO2 Concentration'],
        year: '2023',
        operate_org: 'KIST 한국과학기술연구원',
        owner: 'KIST 한국과학기술연구원',
      },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '중랑',
    coordinates: [127.0794, 37.5906],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2015-2019', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '광화',
    coordinates: [126.9779, 37.5724],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2015-2019', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '가좌',
    coordinates: [126.9136, 37.5837],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2015-2019', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '구로',
    coordinates: [126.8945, 37.4844],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2015', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '안양',
    coordinates: [126.9618, 37.3943],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2015', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '여의',
    coordinates: [126.9206, 37.5345],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2015', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '부천',
    coordinates: [126.7781, 37.5426],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2015', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '송도',
    coordinates: [126.6547, 37.3825],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2017', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '일산',
    coordinates: [126.7307, 37.685],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2017', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '용인',
    coordinates: [127.2669, 37.3362],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2017', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '노원',
    coordinates: [127.0566, 37.6544],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2017', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '성남',
    coordinates: [127.1422, 37.441],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2017', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '뚝섬',
    coordinates: [127.0597, 37.5323],
    equipments: [{ name: 'cambell', materials: ['CO2 Flux'], year: '2017', operate_org: '국립기상과학원', owner: '국립기상과학원' }],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '시흥(KCP)',
    coordinates: [126.7173, 37.33645],
    equipments: [
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'LI-7500RS',
        materials: ['CO2 Flux'],
        year: '2024',
        operate_org: '서울대학교',
        owner: '서울대학교',
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'CSAT3B',
        materials: ['Wind direction and speed'],
        year: '2024',
        operate_org: '서울대학교',
        owner: '서울대학교',
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'Biomet System',
        materials: ['Weather sensor'],
        year: '2024',
        operate_org: '서울대학교',
        owner: '서울대학교',
      },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '남산(KCP)',
    coordinates: [126.9921, 37.5512],
    equipments: [
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'LI-7500RS',
        materials: ['CO2 Flux'],
        year: '2023',
        operate_org: '서울대학교',
        owner: '산림과학원',
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'CSAT3B',
        materials: ['Wind direction and speed'],
        year: '2023',
        operate_org: '서울대학교',
        owner: '산림과학원',
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'Biomet System',
        materials: ['Weather sensor'],
        year: '2023',
        operate_org: '서울대학교',
        owner: '산림과학원',
      },
      { name: 'LI-7815', materials: ['CO2 Concentration'], year: '2023', operate_org: '서울대학교', owner: '산림과학원' },
      { name: 'FloX', materials: ['Sun-Induced Fluorescence'], year: '2023', operate_org: '서울대학교', owner: '산림과학원' },
    ],
  },
  {
    type: 'khu',
    color: networkLegendData['khu']['color'],
    manage_org: networkLegendData['khu']['title'],
    location: '남산(KCP)',
    // ISSUE: 같은 위경도 좌표값으로 인한 수정
    coordinates: [126.9922, 37.5513],
    equipments: [{ name: 'LI-8250, EGM-5', materials: ['Soil CO2 Flux'], year: '2023', operate_org: '경희대학교', owner: '경희대학교' }],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '창경궁(KCP)',
    coordinates: [126.9952, 37.5834],
    equipments: [
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'LI-7500RS',
        materials: ['CO2 Flux'],
        year: '2024',
        operate_org: '서울대학교',
        owner: '서울대학교',
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'CSAT3B',
        materials: ['Wind direction and speed'],
        year: '2024',
        operate_org: '서울대학교',
        owner: '서울대학교',
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'Biomet System',
        materials: ['Weather sensor'],
        year: '2024',
        operate_org: '서울대학교',
        owner: '서울대학교',
      },
      { name: 'LI-7815', materials: ['CO2 Concentration'], year: '2024', operate_org: '서울대학교', owner: '산림과학원' },
      {
        name: 'FloX',
        materials: ['Sun-Induced Fluorescence'],
        year: '2024 (예정)',
        operate_org: '서울대학교',
        owner: '서울대학교',
      },
    ],
  },
  {
    type: 'khu',
    color: networkLegendData['khu']['color'],
    manage_org: networkLegendData['khu']['title'],
    location: '창경궁(KCP)',
    // ISSUE: 같은 위경도 좌표값으로 인한 수정
    coordinates: [126.9953, 37.5835],
    equipments: [{ name: 'LI-8250, EGM-5', materials: ['Soil CO2 Flux'], year: '2023', operate_org: '경희대학교', owner: '경희대학교' }],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '선정릉(KCP)',
    coordinates: [127.0492, 37.5088],
    equipments: [
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'LI-7500RS',
        materials: ['CO2 Flux'],
        year: '계획중',
        operate_org: '서울대학교',
        owner: '산림과학원',
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'CSAT3B',
        materials: ['Wind direction and speed'],
        year: '계획중',
        operate_org: '서울대학교',
        owner: '산림과학원',
      },
      {
        name: 'Eddy Covariance System (LI-COR)',
        subname: 'Biomet System',
        materials: ['Weather sensor'],
        year: '계획중',
        operate_org: '서울대학교',
        owner: '산림과학원',
      },
      { name: 'LI-7815', materials: ['CO2 Concentration'], year: '계획중', operate_org: '서울대학교', owner: '산림과학원' },
      { name: 'FloX', materials: ['Sun-Induced Fluorescence'], year: '계획중', operate_org: '서울대학교', owner: '서울대학교' },
    ],
  },
  {
    type: 'khu',
    color: networkLegendData['khu']['color'],
    manage_org: networkLegendData['khu']['title'],
    location: '선정릉(KCP)',
    // ISSUE: 같은 위경도 좌표값으로 인한 수정
    coordinates: [127.0493, 37.5089],
    equipments: [{ name: 'LI-8250, EGM-5', materials: ['Soil CO2 Flux'], year: '계획중', operate_org: '경희대학교', owner: '경희대학교' }],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '경기도 광주시 (태화산 침엽수림, TCK)',
    coordinates: [127.31722, 37.30472],
    equipments: [{ name: 'LI-7500A', materials: ['CO2 Flux'], year: '2015', operate_org: '서울대학교', owner: '서울대학교' }],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '경기도 광주시 (태화산 활엽수림, TBK)',
    coordinates: [127.3138, 37.3038],
    equipments: [{ name: 'LI-7500A', materials: ['CO2 Flux'], year: '2016', operate_org: '서울대학교', owner: '서울대학교' }],
  },
  {
    type: 'khu',
    color: networkLegendData['khu']['color'],
    manage_org: networkLegendData['khu']['title'],
    location: '경기도 구리시 (조경지)',
    coordinates: [127.14083, 37.57861],
    equipments: [{ name: 'EGM-5', materials: ['Soil CO2 Flux'], year: '2023', operate_org: '경희대학교', owner: '경희대학교' }],
  },

  {
    type: 'jnu',
    color: networkLegendData['jnu']['color'],
    manage_org: networkLegendData['jnu']['title'],
    location: '전라남도 나주 (NRK01)',
    coordinates: [126.820921, 35.027536],
    equipments: [{ name: 'LI-7500RS', materials: ['CO2 Flux'], year: '2020', operate_org: '전남대학교', owner: '전남대학교' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '전라남도 나주 (NRK02)',
    coordinates: [126.821979, 35.026938],
    equipments: [
      {
        name: 'LI-7500A, LI-7700',
        materials: ['CO2', 'CH4 Flux'],
        year: '2021',
        operate_org: '전남대학교',
        owner: '한국항공우주연구원',
      },
    ],
  },
  {
    type: 'jnu',
    color: networkLegendData['jnu']['color'],
    manage_org: networkLegendData['jnu']['title'],
    location: '전라남도 해남 (HFK)',
    coordinates: [126.569925, 34.553807],
    equipments: [{ name: 'LI-7500A', materials: ['CO2 Flux'], year: '2024', operate_org: '전남대학교', owner: '전남대학교' }],
  },
  {
    type: 'jnu',
    color: networkLegendData['jnu']['color'],
    manage_org: networkLegendData['jnu']['title'],
    location: '전라남도 해남 (HSK)',
    coordinates: [126.6442, 34.6402],
    equipments: [{ name: 'LI-7500DS', materials: ['CO2 Flux'], year: '2024', operate_org: '전남대학교', owner: '전남대학교' }],
  },
  {
    type: 'jnu',
    color: networkLegendData['jnu']['color'],
    manage_org: networkLegendData['jnu']['title'],
    location: '전라남도 해남 (HOK)',
    coordinates: [126.572402, 34.555123],
    equipments: [{ name: 'LI-7500DS', materials: ['CO2 Flux'], year: '2024', operate_org: '전남대학교', owner: '전남대학교' }],
  },

  {
    type: 'knu',
    color: networkLegendData['knu']['color'],
    manage_org: networkLegendData['knu']['title'],
    location: '강원도 춘천 (강원대학교)',
    coordinates: [127.7434837, 37.8719823],
    equipments: [
      { name: 'SNUCO2M (LI-850)', materials: ['CO2 Concentration'], year: '2023', operate_org: '강원대학교', owner: '강원대학교' },
    ],
  },
  {
    type: 'knu',
    color: networkLegendData['knu']['color'],
    manage_org: networkLegendData['knu']['title'],
    location: '강원도 춘천 (금병산)',
    coordinates: [127.74853, 37.805569],
    equipments: [
      { name: 'SNUCO2M (LI-850)', materials: ['CO2 Concentration'], year: '2023', operate_org: '강원대학교', owner: '강원대학교' },
    ],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '관악산, 활엽수',
    coordinates: [126.957, 37.457],
    equipments: [{ name: 'EGM-5', materials: ['Soil CO2 Flux'], year: '2024', operate_org: '서울대학교', owner: '서울대학교' }],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '관악산, 침엽수',
    coordinates: [126.9568, 37.4571],
    equipments: [{ name: 'EGM-5', materials: ['Soil CO2 Flux'], year: '2024', operate_org: '서울대학교', owner: '서울대학교' }],
  },
  {
    type: 'snu',
    color: networkLegendData['snu']['color'],
    manage_org: networkLegendData['snu']['title'],
    location: '백운산, 침엽수',
    coordinates: [127.6039, 35.0315],
    equipments: [{ name: 'Li-850', materials: ['Soil CO2 Flux'], year: '2024 (예정)', operate_org: '서울대학교', owner: '서울대학교' }],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '부산시 부산대 (701동)',
    coordinates: [129.075052, 35.2320481],
    equipments: [
      { name: 'SNUCO2M (LI-850)', materials: ['CO2 Concentration'], year: '2024', operate_org: '부산대학교', owner: '부산대학교' },
    ],
  },
  {
    type: 'partner',
    color: networkLegendData['partner']['color'],
    manage_org: networkLegendData['partner']['title'],
    location: '부산시 부산대 (303동)',
    coordinates: [129.0811203, 35.2306586],
    equipments: [
      { name: 'SNUCO2M (LI-850)', materials: ['CO2 Concentration'], year: '2024', operate_org: '부산대학교', owner: '부산대학교' },
    ],
  },
];

export const networkColumnData = {
  name: '장비명',
  materials: '관측물질',
  year: '구축년도',
  owner: '소유기관',
  operate_org: '운영기관',
};
