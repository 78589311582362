import React, { useEffect, useRef } from 'react';
import MemberItem, { IMemberInfo } from './MemberItem';
import styled, { css } from 'styled-components';
import { ReactComponent as ArrowIcon } from '@assets/icons/chevron_right_icon.svg';

type Props = {
  id: number;
  title: string;
  member: IMemberInfo[];
  selected?: boolean;
  onClick: (id: number) => void;
};

const MemberGroup = ({ id, title, member, selected, onClick }: Props) => {
  const ref = useRef<HTMLDivElement>(null);

  useEffect(() => {
    if (!selected) return;
    ref.current && window.scrollTo({ top: ref.current.offsetTop - 60, behavior: 'smooth' });
  }, [selected]);

  return (
    <Container ref={ref} $selected={selected !== undefined ? selected : id === 1}>
      <Accordion onClick={() => onClick(id)}>
        {title}
        <ArrowIcon />
      </Accordion>
      <MemberWrapper>
        {member.map((item, idx) => (
          <MemberItem key={idx} member={item} />
        ))}
      </MemberWrapper>
    </Container>
  );
};

export default MemberGroup;

const Accordion = styled.div`
  display: flex;
  gap: 20px;
  align-items: center;
  justify-content: space-between;

  border-radius: 5px;
  background-color: ${({ theme }) => theme.newColors.dark2};
  color: ${({ theme }) => theme.newColors.white};

  font-size: 20px;
  font-variation-settings: 'wght' 700;
  line-height: 30px; /* 150% */
  letter-spacing: -0.4px;

  padding: 15px;
  cursor: pointer;

  svg {
    transform: rotate(90deg);
  }

  ${({ theme }) =>
    theme.media.tablet(`
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 18px;
      line-height: 28px; /* 155.556% */
      letter-spacing: -0.36px;
  `)}
`;

const MemberWrapper = styled.div`
  display: none;
  grid-template-columns: repeat(2, 1fr);
  gap: 30px;
  margin-block: 30px 20px;

  ${({ theme }) =>
    theme.media.tablet(`
      gap: 20px;
  `)}

  ${({ theme }) =>
    theme.media.mobile(`
      grid-template-columns: repeat(1, 1fr);
      gap: 20px;
  `)}
`;

const Container = styled.div<{ $selected: boolean }>`
  display: flex;
  flex-direction: column;
  width: 100%;

  ${({ $selected }) =>
    $selected &&
    css`
      ${Accordion} {
        background-color: ${({ theme }) => theme.newColors.primary};

        svg {
          transform: rotate(-90deg);
        }
      }

      ${MemberWrapper} {
        display: grid;
      }
    `}
`;
