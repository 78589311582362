import { Link } from 'react-router-dom';
import styled from 'styled-components';

const NotFound = () => {
  return (
    <Container>
      <Title>404 ERROR</Title>
      <Content>
        <span>{`죄송합니다. 페이지를 찾을 수 없습니다.\n요청하신 페이지가 사라졌거나, `}</span>
        <span>{`잘못된 경로를 이용하셨습니다.`}</span>
      </Content>
      <Link to={'/'}>
        <GoToHome>메인 페이지로 이동하기</GoToHome>
      </Link>
    </Container>
  );
};

export default NotFound;

const GoToHome = styled.button`
  margin-top: 100px;
  padding: 0px 10px;
  height: 35px;

  color: ${({ theme }) => theme.colors.white};
  font-size: 14px;
  font-variation-settings: 'wght' 500;
  line-height: 17px;
  letter-spacing: -0.28px;
  border-radius: 3px;
  background-color: ${({ theme }) => theme.colors.gray};

  ${({ theme }) =>
    theme.media.mobile(`
      margin-top: 40px;
  `)}
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 40px;
  width: 100%;
  height: 100vh;
  height: 100svh;

  ${({ theme }) =>
    theme.media.mobile(`
      gap: 20px;
  `)}
`;

const Title = styled.h1`
  font-size: 60px;
  font-variation-settings: 'wght' 500;
  letter-spacing: -1.2px;

  ${({ theme }) =>
    theme.media.mobile(`
      font-size: 35px;
      line-height: normal;
      letter-spacing: -0.7px;
  `)}
`;

const Content = styled.span`
  font-size: 20px;
  font-variation-settings: 'wght' 400;
  line-height: 35px;
  letter-spacing: -0.4px;
  text-align: center;
  white-space: pre-line;

  ${({ theme }) =>
    theme.media.mobile(`
      display: flex;
      flex-direction: column;
      max-width: 241px;
      font-size: 16px;
      line-height: 28px; /* 175% */
      letter-spacing: -0.32px;
  `)}
`;
