import styled from 'styled-components';

type Props = {
  imgSrc: string;
};

const SwiperItem = ({ imgSrc }: Props) => {
  return (
    <Container>
      <img src={imgSrc} width={1248} height={421} />
    </Container>
  );
};

export default SwiperItem;

const Container = styled.div`
  position: relative;
  width: 100%;
  height: 100%;

  display: flex;
  align-items: end;
  justify-content: center;

  img {
    object-fit: contain;
    width: 100%;
  }
`;
