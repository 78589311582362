import useWidthType from '@hooks/useWidthType';
import { sizes } from '@styles/theme';
import styled from 'styled-components';

export type OrganizationsType = {
  title: string;
  description: string;
  img: string;
};

type Props = {
  data: OrganizationsType;
};

const OrganizationItem = ({ data }: Props) => {
  const breakpoint = useWidthType();
  const imgSrc = breakpoint === 'desktop' ? `${data.img}.png` : `${data.img}_${breakpoint}.png`;

  return (
    <Container>
      <TextContainer>
        <span className="title">{data.title}</span>
        <span>{data.description}</span>
      </TextContainer>
      <img src={imgSrc} />
    </Container>
  );
};

export default OrganizationItem;

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 50px;
  width: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;

  color: ${({ theme }) => theme.newColors.white};
  font-size: 18px;
  font-variation-settings: 'wght' 400;
  line-height: 30px;
  letter-spacing: -0.36px;

  span.title {
    font-size: 25px;
    font-variation-settings: 'wght' 800;
    line-height: 40px;
    letter-spacing: -0.5px;
  }

  ${({ theme }) =>
    theme.media.mobile(`
      span.title {
        font-size: 20px;
        font-weight: 700;
        line-height: 30px; /* 150% */
        letter-spacing: -0.4px;
      }
  `)}
`;
